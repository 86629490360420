<template>
  <b-overlay
    spinner-variant="primary"
    spinner-type="grow"
    spinner-large
    rounded="lg"
    :show="overlayShow"
  >
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">

        <!-- Brand logo-->
        <b-link
            class="brand-logo"
            to="/"
        >
          <vuexy-logo class="" style="width: 250px"/>
        </b-link>
        <!-- /Brand logo-->

        <!-- Left Text-->
        <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
        >
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img
              fluid
              :src="imgUrl"
              alt="Login V2"
            />
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Login-->
        <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
            >
              Добро пожаловать! 👋
            </b-card-title>
            <b-card-text class="mb-2">
              Вход в свой Аккаунт
            </b-card-text>

            <!-- form -->
            <validation-observer ref="loginForm">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
              >
                <!-- email -->
                <b-form-group
                  label="Логин"
                  label-for="login-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="логин"
                    rules="required"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false:null"
                      name="login-email"
                      placeholder="имя пользователь"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Пароль</label>
                    <b-link :to="{name:'forgot-password'}" id="login-password">
                      <small>Забыли пароль?</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="пароль"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    Запомнить
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  @click="login"
                >
                  Вход
                </b-button>
              </b-form>
            </validation-observer>

            <b-card-text class="text-center mt-2">
              <span>У вас нет учетной записи? </span>
              <b-link :to="{name:'register'}">
                <span>&nbsp;Регистрация</span>
              </b-link>x
            </b-card-text>
          </b-col>
        </b-col>
        <!-- /Login-->
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BOverlay,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import http from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ru from 'vee-validate/dist/locale/ru.json'
import router from '@/router'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      overlayShow: false,
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    localize('ru', ru)
  },
  methods: {
    login() {
      this.$refs.loginForm.validate()
        .then(success => {
          if (success) {
            this.overlayShow = true
            http.post('https://edu.startupchoikhona.tj/backend/api/login', {
              email: this.userEmail,
              password: this.password,
            })
              .then(response => {
                const roleTranslate = {
                  admin: 'Администратор',
                  student: 'Студент',
                  teacher: 'Ментор',
                }
                const userData = response.data.data
                useJwt.setToken(userData.access_token)
                useJwt.setRefreshToken(userData.refresh_token)
                localStorage.setItem('userData', JSON.stringify(userData))
                // Update when logged in
                this.$ability.update(userData.ability)
                if (Object.keys(this.$router.currentRoute.query).length > 0 && this.$router.currentRoute.query.redirectTo.href) {
                  window.location = this.$router.currentRoute.query.redirectTo.href
                }
                if (Object.keys(this.$router.currentRoute.query).length > 0 && this.$router.currentRoute.query.redirectTo) {
                  window.location = this.$router.currentRoute.query.redirectTo
                }
                // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                if (userData.roles.length > 0) {
                  this.$router.replace(getHomeRouteForLoggedInUser(userData.roles[0]))
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: response.data.message,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: `Вы успешно вошли в систему как ${roleTranslate[userData.roles[0]]}. Теперь вы можете начать работу!`,
                        },
                      })
                    })
                    .catch(error => {
                      this.$refs.loginForm.setErrors(error.response.data.error)
                    })
                }
                this.overlayShow = false
              })
              .catch(error => {
                this.overlayShow = false
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Ошибка',
                    icon: 'CloseIcon',
                    variant: 'danger',
                    text: error.response.data.message,
                  },
                })
              })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
